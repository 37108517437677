import LoginSocialBarComponent from '../components/LoginSocialBar/viewer/LoginSocialBar';
import LoginSocialBarController from '../components/LoginSocialBar/viewer/LoginSocialBar.controller';


const LoginSocialBar = {
  component: LoginSocialBarComponent,
  controller: LoginSocialBarController
};


export const components = {
  ['LoginSocialBar']: LoginSocialBar
};

